<template>
  <Calendar> </Calendar>
</template>
<script>
import Calendar from "./ecran/Calendar";
import { viewLotMixin } from "../mixins/view_lot";

export default {
  components: { Calendar },
  mixins: [viewLotMixin]
};
</script>
